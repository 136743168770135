import { render, staticRenderFns } from "./Ico.vue?vue&type=template&id=26e1b1d6&"
import script from "./Ico.ts?vue&type=script&lang=ts&"
export * from "./Ico.ts?vue&type=script&lang=ts&"
import style0 from "./Ico.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports