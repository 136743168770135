import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface IcoModel {
	name: string;
	size?: string;
}

// Props -------------------------------------------------------------------------------------------

export const IcoProps: Props<IcoModel> = {
	name: {
		type: String as PropType<IcoModel['name']>,
		required: true,
	},
	size: {
		type: String as PropType<IcoModel['size']>,
	},
};
