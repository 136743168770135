import Vue from 'vue';
import { importRunTask } from '../../assets/js/utilities/dynamicImports';
import {
	ClsComponentMixin,
	IClsComponentMixinExtended,
} from '../../components/mixins/cls-component-mixin';
import { IcoProps } from './Ico.props';
import { ICON_CODES } from './Ico.codes';

// Constant(s) -------------------------------------------------------------------------------------

const importFontFaceObserver = () => import('fontfaceobserver');
const ICON_FONTNAME = 'ochsnersport';

export function getIconCode(name: string): string {
	return name in ICON_CODES ? String.fromCodePoint(ICON_CODES[name]) : '';
}

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Ico',
	mixins: [ClsComponentMixin],
	props: IcoProps,
	data() {
		return {
			loaded: false,
			icon: undefined as undefined | string,
		};
	},
	fetch() {
		this.icon = getIconCode(this.name);
	},
	watch: {
		name(newName: string) {
			this.icon = getIconCode(newName);
		},
	},
	created() {
		if (!this.icon) {
			this.icon = getIconCode(this.name);
		}

		// When server side rendering, set loaded to true to show / render icons initially
		if (process.server) {
			this.loaded = true;
		}
	},
	mounted() {
		this.loaded = (this as unknown as IClsComponentMixinExtended).isServerSideRendered();

		if (!this.loaded) {
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					importFontFaceObserver().then(({ default: FontFaceObserver }) => {
						new FontFaceObserver(ICON_FONTNAME).load().then(() => {
							this.loaded = true;
						});
					});
				});
			});
		}
	},
});
