// prettier-ignore
export const ICON_CODES: { [key: string]: number } = {
	'add': 59393,
	'add-small': 59392,
	'address-book': 59395,
	'address-book-filled': 59394,
	'address-new': 59397,
	'address-new-filled': 59396,
	'address-set': 59399,
	'address-set-filled': 59398,
	'address-standard': 59401,
	'address-standard-filled': 59400,
	'alert': 59403,
	'alert-filled': 59402,
	'apps': 59404,
	'arrow-down': 59410,
	'arrow-down-left': 59406,
	'arrow-down-left-small': 59405,
	'arrow-down-right': 59408,
	'arrow-down-right-small': 59407,
	'arrow-down-small': 59409,
	'arrow-left': 59412,
	'arrow-left-small': 59411,
	'arrow-right': 59415,
	'arrow-right-long': 59413,
	'arrow-right-small': 59414,
	'arrow-subdirectory-down': 59416,
	'arrow-subdirectory-left': 59418,
	'arrow-subdirectory-left-1': 59417,
	'arrow-subdirectory-up': 59419,
	'arrow-up': 59425,
	'arrow-up-left': 59421,
	'arrow-up-left-small': 59420,
	'arrow-up-right': 59423,
	'arrow-up-right-small': 59422,
	'arrow-up-small': 59424,
	'assembly': 59426,
	'at': 59428,
	'at-square': 59427,
	'back': 59430,
	'back-filled': 59429,
	'bonus': 59432,
	'bonus-filled': 59431,
	'bulky-delivery': 59434,
	'bulky-delivery-filled': 59433,
	'cancel': 59436,
	'cancel-filled': 59435,
	'chat': 59438,
	'chat-filled': 59437,
	'chevron-down': 59440,
	'chevron-down-small': 59439,
	'chevron-left': 59442,
	'chevron-left-small': 59441,
	'chevron-right': 59446,
	'chevron-right-circle': 59444,
	'chevron-right-circle-filled': 59443,
	'chevron-right-small': 59445,
	'chevron-up': 59448,
	'chevron-up-small': 59447,
	'clock': 59450,
	'clock-filled': 59449,
	'close': 59451,
	'competition': 59453,
	'competition-filled': 59452,
	'cookie': 59454,
	'delivery': 59457,
	'delivery-circle': 59455,
	'delivery-filled': 59456,
	'done': 59458,
	'dots': 59459,
	'edit': 59461,
	'edit-filled': 59460,
	'error': 59463,
	'error-filled': 59462,
	'event-filled': 59465,
	'events': 59464,
	'filter': 59467,
	'filter-filled': 59466,
	'fullscreen-off': 59468,
	'fullscreen-on': 59469,
	'globe': 59471,
	'globe-filled': 59470,
	'help': 59475,
	'help-filled': 59472,
	'help-live': 59474,
	'help-live-filled': 59473,
	'hint': 59477,
	'hint-filled': 59476,
	'home': 59479,
	'home-filled': 59478,
	'info': 59481,
	'info-filled': 59480,
	'invoice': 59483,
	'invoice-filled': 59482,
	'less': 59484,
	'list': 59486,
	'location-pin': 59487,
	'location-pin-filled': 59485,
	'lock': 59489,
	'lock-filled': 59488,
	'magazine': 59491,
	'magazine-filled': 59490,
	'menu': 59492,
	'money': 59494,
	'money-filled': 59493,
	'more': 59495,
	'new': 59497,
	'new-filled': 59496,
	'open-in-browser': 59499,
	'open-in-browser-filled': 59498,
	'order': 59504,
	'order-filled': 59500,
	'order-overview': 59503,
	'order-overview-circle': 59501,
	'order-overview-filled': 59502,
	'outlet': 59506,
	'outlet-filled': 59505,
	'paused': 59508,
	'paused-filled': 59507,
	'payment': 59510,
	'payment-filled': 59509,
	'phone': 59513,
	'phone-circle': 59511,
	'phone-filled': 59512,
	'pickup': 59515,
	'pickup-filled': 59514,
	'plane': 59517,
	'plane-filled': 59516,
	'play': 59519,
	'play-filled': 59518,
	'pos': 59521,
	'pos-filled': 59520,
	'rabbit': 59522,
	'refresh': 59523,
	'remove': 59525,
	'remove-small': 59524,
	'return-in-store': 59527,
	'return-in-store-filled': 59526,
	'returns': 59529,
	'returns-filled': 59528,
	'sale': 59531,
	'sale-square': 59530,
	'search': 59532,
	'service': 59533,
	'settings': 59535,
	'settings-filled': 59534,
	'share': 59536,
	'shirt': 59538,
	'shirt-filled': 59537,
	'shopping-cart': 59540,
	'shopping-cart-filled': 59539,
	'size': 59542,
	'size-filled': 59541,
	'spin': 59543,
	'star': 59546,
	'star-filled': 59544,
	'star-half': 59545,
	'swiss-cross-square': 59547,
	'trash': 59549,
	'trash-filled': 59548,
	'travel-suitcase': 59551,
	'travel-suitcase-filled': 59550,
	'umbrella': 59553,
	'umbrella-filled': 59552,
	'user': 59556,
	'user-filled': 59554,
	'user-secret': 59555,
	'users': 59557,
	'wishlist': 59559,
	'wishlist-filled': 59558,
};
