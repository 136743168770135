import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface LoginCtaModel {
	loggedIn: boolean;
	initials?: string;
	active?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const LoginCtaProps: Props<LoginCtaModel> = {
	loggedIn: {
		type: Boolean as PropType<LoginCtaModel['loggedIn']>,
		required: true,
	},
	initials: {
		type: String as PropType<LoginCtaModel['initials']>,
		default: '',
	},
	active: {
		type: Boolean as PropType<LoginCtaModel['active']>,
		default: false,
	},
};
